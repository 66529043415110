<template>
  <div class="personal-back">
    <div class="personal-title">
      <div class="title-bg">
        <h4>反馈回复</h4>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>反馈回复</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="personal-container">
      <div class="back-list" v-if="backList.length > 0" v-for="(back, index) in backList">
        <div class="list-top">
          <div class="time">{{back.create_time}}</div>
        </div>
        <div class="list-content" v-if="back.status != 0 && back.check_status == 2">
          <div class="replier">给您回复：</div>
          <div class="replier-content">{{back.handler_content}}</div>
        </div>
        <div v-else class="list-content">
          <div class="replier">{{back.check_status_desc}}</div>
        </div>
        <div class="list-qa"><span>留言内容：</span>{{back.content}}</div>
      </div>
      <xgj-empty v-if="records === 0" title="暂无反馈回复数据"></xgj-empty>
      <div class="news-pages" v-if="backList.length > 0">
        <el-pagination
          background
          @size-change="sizeDetailChange"
          @current-change="currentDetailChange"
          :current-page.sync="detail_paging.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="detail_paging.size"
          :total="detail_paging.total_elements"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'personal-back',
    data() {
      return {
        detail_paging: {  //分页
          page: 1,
          size: 10,
          total_elements: 0
        },
        backList: [],
        records: null
      }
    },
    created() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(0, 0, 0, 0.3)'
      })
      Promise.all([this.getQueryInteraction()])
        .then(() => {
          loading.close()
        }).catch(reason => {
          loading.close()
        })
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        partnerId: state => state.user.partnerId
      })
    },
    methods: {
      //改变每页数量
      sizeDetailChange(size) {
        this.detail_paging.size = size;
        this.getQueryInteraction()
      },

      //改变页数
      currentDetailChange(page) {
        this.detail_paging.page = page;
        this.getQueryInteraction()
      },
      getQueryInteraction() {
        const that = this;
        return new Promise((resolve,reject) => {
          let params = {
            userId: that.userInfo.id,
            partnerId: that.partnerId,
            page: that.detail_paging.page,
            rows: that.detail_paging.size
          };
          that.$api.website.getQueryInteraction(params)
            .then( dates => {
              this.backList = [];
              if (dates.data.length > 0) {
                dates.data.forEach((data) => {
                  this.backList.push(data)
                });
                that.detail_paging.size = dates.paging.page_size;
                that.detail_paging.page = dates.paging.current_page;
                that.detail_paging.total_elements = dates.paging.records;
                that.threeStatus = true;
              } else {
                this.records = 0
              }
              resolve()
            }).catch( err => {
              reject()
            })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "./personal-view.scss";
</style>
